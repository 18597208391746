import * as React from 'react'
import Header from '../../components/Header/Header';
import './../../sass/main.scss'
import Footer from '../../components/Footer/Footer';
import JobMap from '../../components/JobMap';
import PageDetails from '../../components/PageDetails';

let isLocal = ['dev', 'development'].includes( process.env.NODE_ENV );
let fr_url = (isLocal ? '' : process.env.FR_URL) + '/fr/';
const lang = process.env.LANG;
const isFrench = lang === 'FR';

const EnPage = () => {
    
  if (isFrench) {
    return (
      <meta
        http-equiv="refresh"
        content={`0;url=${process.env.EN_URL}/en/`}
        id="redirectTag"
      />
    );
  }
    return (
        <>
            <Header title={'Careers at our Canadian offices abroad | Global Affairs Canada'}
                    description={'Browse all the job opportunities at our Canadian offices abroad.'}
                    lang={'EN'}
                    langLink={fr_url}
                    bodyClasses={'page-en'}/>
            <main property="mainContentOfPage" resource="#wb-main" class="container pb-5 page-type-search page-search"
                  typeof="WebPageElement">

                <h1 className={''}>Job Opportunities at our Canadian offices abroad</h1>
                <p>What we do matters. We define, shape and advance Canada’s interests and values in a complex and
                    evolving global environment. We manage diplomatic relations, promote international trade and
                    provide consular support to Canadians. We lead international development, humanitarian, and
                    peace and security assistance efforts. We also contribute to national security and the
                    development of international law.</p>
                <h2>On this page</h2>
                <ul>
                    <li><a href={'#map'}>Find jobs</a></li>
                    <li><a href={'#borders'}>A career without borders</a></li>
                    <li><a href={'#engagement'}>Locally engaged staff</a></li>
                    <li><a href={'#notices-updates'}>Notices / updates</a></li>
                </ul>
                <h2 id={isFrench ? 'mappe' : 'map'}>{isFrench ? 'Rechercher les emplois' : 'Find jobs'}</h2>
                <p>Perform a keyword search by title, country, city, or program title to see job opportunities at our
                    Canadian offices abroad.</p>
                <JobMap/>

                <h2 id={'borders'}>A Career without borders</h2>
                <p>Be part of a world-class institution. Canada’s professional, non-partisan public service is among
                    the best in the world. To maintain our reputation, we need people who are talented, skilled and
                    passionate about their work.</p>

                <p> Get a career with us : a career to match your passion that has real meaning and impact on the
                    lives of Canadians. Contribute to Canada’s place in the world.</p>

                <p>We are a worldwide network of professionals. We work in over <a
                    href={'https://www.international.gc.ca/world-monde/country-pays/index.aspx?lang=eng'}
                    target={'_blank'} rel={'noreferrer'}>175 offices</a> around the
                    world.
                    Canada’s offices abroad include Canada-Based Staff and Locally-Engaged Staff.</p>

                <p><b>We work together to strengthen Canada’s relations with the world. </b></p>

                <h2 id={'engagement'}>Locally engaged staff (LES)</h2>
                <div className={'container row'}>
                    <div className={'col-md-6'} style={{paddingLeft: 0}}>
                        <p>Locally Engaged Staff (LES) form an important part of Global Affairs Canada's
                            workplace.</p>
                        <p>
                            They are appointed and employed outside of Canada to perform duties that are directly
                            related to the government of Canada Missions’ operations located outside Canada. They
                            support the delivery of the programs and services of:
                        </p>
                        <ul>
                            <li>the Government of Canada</li>
                            <li>provinces</li>
                            <li>other partners abroad</li>
                        </ul>
                        <p>LES are essential to carry out each of their mandates abroad. </p>
                    </div>
                    <div className={'col-md-6'} style={{paddingLeft: 0}}>
                        <p>LES perform a wide variety of tasks, from maintenance work to expert services. Their work
                            helps to operate a Canadian mission abroad. We hire LES for their knowledge of:
                        </p>
                        <ul>
                            <li>local languages</li>
                            <li>context</li>
                            <li>business specifics</li>
                            <li>networks</li>
                        </ul>
                        <p>Our public service is building a culture that encourages innovation, new ideas and
                            approaches, better collaboration and engagement. Make a difference and be part of
                            shaping this modern public service, we will be happy to have you on board!</p>
                    </div>
                    <h2 id={'notices-updates'}>Notices / Updates</h2>
                    <p>
                        Unless specified on the job poster, Canada will not sponsor work authorizations through this
                        selection process. If you get the job, you need to legally reside and have legal authorization
                        to work in that country for the duration of the contract period.
                    </p>
                    <p/>
                    <p className="alert alert-info">As recently announced by the Government of Canada, mandatory
                        vaccination against COVID-19 at Canada’s diplomatic and consular missions abroad is suspended as
                        of June 20, 2022. The Government of Canada will continue to assess the need for additional
                        public health measures, including the possible reintroduction of a vaccination mandate at a
                        later date.</p>
                    <p/>
                </div>
                <PageDetails lang={'EN'} dateModified={'2022-01-05'}/>
            </main>
            <Footer lang={'EN'}/>
        </>
    )
}

export default EnPage;
